import { createRef, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Layout from 'components/Layout/Layout';
import { NotifyMeProvider } from 'hooks/useNotifyMe';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage/NotFoundPage'));

const AboutPage = lazy(() => import('pages/AboutPage/AboutPage'));
const TermsOfServicePage = lazy(() => import('pages/TermsOfServicePage/TermsOfServicePage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage'));
const UnsubscribeFlowPage = lazy(() => import('pages/UnsubscribeFlowPage/UnsubscribeFlowPage'));

export const routes = [
  { path: '/', name: 'Home', element: <HomePage />, nodeRef: createRef() },
  {
    path: '/about',
    name: 'About',
    element: <AboutPage />,
    nodeRef: createRef(),
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    element: <TermsOfServicePage />,
    nodeRef: createRef(),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    element: <PrivacyPolicyPage />,
    nodeRef: createRef(),
  },
  {
    path: '/w/unsubscribe/:emailToken',
    name: 'UnsubscribeFlow',
    element: <UnsubscribeFlowPage />,
    nodeRef: createRef(),
  },
  {
    path: '/*',
    name: 'NotFound',
    element: <NotFoundPage />,
    nodeRef: createRef(),
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <NotifyMeProvider>
        <Layout />
      </NotifyMeProvider>
    ),
    children: routes.map(route => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
      loader: route.loader,
    })),
  },
]);
