import { createContext, useCallback, useContext, useState } from 'react';

const NotifyMeContext = createContext(null);

export const NotifyMeProvider = ({ children }) => {
  const [hasSentEmail, setHasSentEmail] = useState(false);

  const markEmailAsSent = useCallback(() => {
    setHasSentEmail(true);
  }, []);

  return (
    <NotifyMeContext.Provider value={{ hasSentEmail, markEmailAsSent }}>
      {children}
    </NotifyMeContext.Provider>
  );
};

export const useNotifyMe = () => useContext(NotifyMeContext);
