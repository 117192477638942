import { Helmet } from "react-helmet-async";
import { routes } from "routes/AllRoutes";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import LoadingDisplay from "components/LoadingDisplay/LoadingDisplay";

import { isPathMatch } from "services/utilities";
import { SIZE_URL_BAR, TIMEOUT_FOR_CSS_TRANSITION } from "services/constants";
import { useIsAppleDevice } from "hooks/useIsAppleDevice";

const Layout = () => {
  const currentOutlet = useOutlet();
  const location = useLocation();

  const isAppleDevice = useIsAppleDevice();
  const nodeRef = useMemo(() => {
    for (const route of routes) {
      if (route.path === "/*") continue;
      if (isPathMatch(route.path, location.pathname)) {
        return route.nodeRef;
      }
    }
    return routes.find((route) => route.path === "/*")?.nodeRef;
  }, [location.pathname]);

  const handleAnimationStart = useCallback(() => {
    // URL bar can take up to 70px.

    window.scroll({
      top: isAppleDevice ? -SIZE_URL_BAR : 0,
      left: 0,
    });
  }, [isAppleDevice]);

  useEffect(() => {
    // Disable the browser's automatic scroll recovery, which it does before the animation starts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Reveal What's Inside Your Skincare with SkinBuddy"
        />
        <meta
          property="og:description"
          content="Unlock the secrets of your skincare. SkinBuddy reveals the ingredients and matches you with products perfect for your skin."
        />
      </Helmet>

      <Suspense fallback={<LoadingDisplay />}>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={TIMEOUT_FOR_CSS_TRANSITION}
            classNames="fade"
            mountOnEnter
            unmountOnExit
            onEnter={handleAnimationStart}
          >
            <div ref={nodeRef}>{currentOutlet}</div>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default Layout;
