import { EMAIL_PATTERN_AUTH } from './constants';

export const isPathMatch = (routePath, currentPath) => {
  if (!routePath.includes('/:')) return routePath === currentPath;

  const basePath = routePath.split('/:')[0];
  return currentPath.startsWith(basePath);
};

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const validateEmail = email => EMAIL_PATTERN_AUTH.test(email);
