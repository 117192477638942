export const CONTACT_EMAIL = 'info@skinbuddy.me';
export const SUPPORT_EMAIL = 'support@skinbuddy.me';
export const BLOG_URL = 'https://blog.skinbuddy.me';

export const APP_STORE_LINK = 'https://www.apple.com/app-store/';
export const GOOGLE_PLAY_STORE_LINK = 'https://play.google.com/store/apps';

export const LAST_UPDATED_APP_DATA = 'March 5, 2025';

export const APP_ENV_PRODUCTION = 'production';
export const APP_ENV_DEVELOPMENT = 'development';
export const APP_ENV_BETA = 'beta';

export const CONTACT_LINK_INSTAGRAM = 'https://www.instagram.com/skinbuddy.me';
export const CONTACT_LINK_YOUTUBE = 'https://www.youtube.com/@Skinbuddy';
export const CONTACT_LINK_TIKTOK = 'https://www.tiktok.com/@skinbuddy.me';

export const API_ENDPOINT_LANDING_SUBSCRIBE = '/landing/subscribe'; // POST
export const API_ENDPOINT_MARKETING_UNSUBSCRIBE_FEWER = '/marketing/unsubscribe/fewer'; // GET
export const API_ENDPOINT_MARKETING_UNSUBSCRIBE_IMPORTANT = '/marketing/unsubscribe/important'; // GET
export const API_ENDPOINT_MARKETING_UNSUBSCRIBE_ALL = '/marketing/unsubscribe/all'; // GET
export const API_ENDPOINT_MARKETING_RESUBSCRIBE = '/marketing/resubscribe'; // GET

export const COMPONENT_AS_BUTTON = 'btn';
export const COMPONENT_AS_LINK = 'link';
export const COMPONENT_AS_UP_BUTTON = 'upButton';

export const BUTTON_TYPE_BUTTON = 'button';
export const BUTTON_TYPE_SUBMIT = 'submit';

export const BUTTON_VARIANT_PRIMARY = 'primary';
export const BUTTON_VARIANT_SECONDARY = 'secondary';
export const BUTTON_VARIANT_TERTIARY = 'tertiary';

// For mobile devices - icon with text, for tablet - icon, for desktop - icon with text
export const LOGO_VARIANT_PRIMARY = 'primary';
//Always icon
export const LOGO_VARIANT_SECONDARY = 'secondary';
//Always icon with text
export const LOGO_VARIANT_TERTIARY = 'tertiary';

export const LOGO_COLOR_PINK = 'pink_logo';
export const LOGO_COLOR_WHITE = 'white_logo';

export const NAVIGATION_MENU_THEME_LIGHT = 'light';
export const NAVIGATION_MENU_THEME_DARK = 'dark';

export const TIMEOUT_FOR_CSS_TRANSITION = 300;
export const TIMEOUT_FOR_CSS_TRANSITION_400 = 400;

export const SIZE_URL_BAR = 70;

export const PLATFORM_WEB_VARIANT_MOBILE_IOS_IPHONE = 'iPhone';
export const PLATFORM_WEB_VARIANT_MOBILE_ANDROID_PHONE = 'AndroidPhone';
export const PLATFORM_WEB_VARIANT_OTHER_DEVICES = 'Other';

export const SCROLL_EVENT_THROTTLE_DELAY_MS = 200;
export const THROTTLE_WINDOW_RESIZE_DELAY = 300;
export const SCROLL_Y_THRESHOLD_SHOW_FLOAT_BUTTON_PX_LANDING = 435;

export const EMAIL_PATTERN_AUTH = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const EMAIL_PATTERN_AUTH_INPUT = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

export const BUILD_TYPE_IOS_LAUNCH = 'ioslaunch';
export const IS_IOS_LAUNCH_BUILD_TYPE = process.env.REACT_APP_BUILD_TYPE === BUILD_TYPE_IOS_LAUNCH;

export const NOTIFICATION_TIMER_MS = 7000;
export const NOTIFICATION_ERROR_TIMER_MS = 3000;

export const UNSUBSCRIBE_FLOW_OPTION_FEWER = 'fewer';
export const UNSUBSCRIBE_FLOW_OPTION_IMPORTANT = 'important';
export const UNSUBSCRIBE_FLOW_OPTION_ALL = 'all';

export const UNSUBSCRIBE_FLOW_ACTIVE_SCREEN_OPTIONS = 'options';
export const UNSUBSCRIBE_FLOW_ACTIVE_SCREEN_PREFERENCES_UPDATED = 'preferencesUpdated';
export const UNSUBSCRIBE_FLOW_ACTIVE_SCREEN_UNSUBSCRIBED_CONFIRMATION = 'unsubscribedConfirmation';
export const UNSUBSCRIBE_FLOW_ACTIVE_SCREEN_RESUBSCRIBE_SUCCESS = 'resubscribeSuccess';
