import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/AllRoutes';
import smoothscroll from 'smoothscroll-polyfill';

import './index.css';
import 'styles/common.scss';
import 'styles/animation.css';

// Initialize the polyfill
smoothscroll.polyfill();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>,
);
